@import "../../variables";

.home{
    display: flex;
    flex-direction: column;
    margin-left: 15%;
    max-width: 900px;
    padding: 30px;

    h2{
        font-size: 99px;
        margin-bottom: 50px;
        margin-top: 200px;
    }
    h3{
        font-size: 52px;
        font-family: 'Roboto', sans-serif;
        margin: 0;
        letter-spacing: 4.5px;
    }
    p{
        margin: 25px 0 0 0;
        font-size: 25px;
        letter-spacing: 2.4px;
    }
    button{
        font-size: 22px;
        margin-top: 100px;
        width: 300px;
        background-color: $GOLD;
        border: 3px solid $GOLD;
        padding: 20px;
        border-radius: 50px;
        font-weight: bold;
        &:hover{
            background-color: white;
            border: 3px solid $GOLD;
            transition: ease-in-out all 400ms;
        }
    }
    a{
        // color: #000;
        text-decoration: none;
    }
    .appointment-button {
        color: black;
    }
}

@media screen and (max-width: 1200px) {
    .home {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 500px) {
    .home{
        padding: 15px;
        h2{
            font-size: 4em;
            margin-top: 100px;
        }
        h3{
            font-size: 3em;
            text-align: center;
        }
        p{
            font-size: 1.5em;
            text-align: center;
        }
        button{
            margin-bottom: 50px;
        }
    }
}