@import "../../variables";

.services-container{
    display: flex;
    flex-direction: column;
    .container-sub {
        width: 50%;
        display: flex;
        justify-content: flex-start;
    }
    .container-sub-left{
        align-items: flex-end;
    }
    .container-sub-right{
        align-items: flex-start;
    }

    .colored-bg{
        background-color: $GOLD;
        position: absolute;
        width: 50vw;
        height: 100vh;
        z-index: -1;

    }
    .colored-sub-container{
        height: 90%;
    }
    .general-container{
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 60%;
    }
    h3{
        font-size: 27px;
        font-weight: bold;
        font-family: 'Roboto', 'sans-serif';
    }
    p{
        font-size: 20px;
        font-family: 'Roboto', 'sans-serif';
    }

    article{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .article-left{
        text-align: right;
        display: flex;
        justify-content: flex-end;
    }
    .article-right{
        text-align: left;
        display: flex;
        justify-content: flex-start;
    }
    .services-txt{
        max-width: 80%;
        margin: 0 30px;
    }
    img{
        max-height: 80px;
        width: auto;
    }
    .icone{
        height: 50px;
        width: auto;
    }
    .box-center-right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        max-width: 80%;
    }
    .box-center-left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        max-width: 80%;
    }
    .service-Dog{
        max-height: 80vh;
        width: auto;
        margin-top: 100px;
    }
}

// * ---------- Small screens ---------- *
@media screen and (max-width: 1030px) {
    .general-container{
        flex-direction: column;
    }
    .service-Dog{
        display: none;
    }
    .services-container{
        height: 100%;
        .colored-bg{
            width: 0;
        }
        .container-sub{
            width: 100%;
            justify-content: center;
            align-items: center;
        }
        .container-sub-left{
            align-items: center;
            background: $GOLD;

        }
        .article-left{
            flex-direction: row-reverse;
            text-align: left;
            justify-content: flex-end;
            width: 100%;
        }
        .article-right{
            width: 80vw;
            justify-content: flex-start;
        }
        .box-center{
            align-items: center;
            margin-bottom: 50px;
        }
        .PageTitle{
            background: $GOLD;
        }
        .icone{
            max-height: 40px;
        }
        h3{
            font-size: 20px;
        }
        p{
            font-size: 16px;
            text-align: justify;
        }
        .divider-black{
            border-bottom: 10px solid white;
        }
    }

}