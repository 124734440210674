.waterColor{
    // height: 95vh;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0.8;
}
.homeDog{
    // height: 95vh;
    width: auto;
    position: fixed;
    z-index: -2;
    right: -250px;
    bottom: 0;
    overflow: hidden;
    opacity: 0.2;
}

// * ---------- SMALL SCREENS ---------- *
@media screen and (max-width: 700px) {
    .homeDog{
        display: none;
    }
}