// --------- FONTS ---------
@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville|Roboto&display=swap');
@import "./variables";

body{
  font-family: 'Roboto', sans-serif;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h1 ,h2, h3, h4, h5, h6{
  font-family: 'Baskerville', sans-serif;
  font-weight: normal;
}

button{
  cursor: pointer;
  button:focus{
    border: none;
  }
}

.flex-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-row{
  display: flex;
  justify-content: center;
  align-items: center;
}


.divider-black{
  border-bottom: 10px solid black;
  width: 50px;
}
.divider-colored{
  border-bottom: 10px solid $GOLD;
  width: 50px;
  margin-bottom: 50px;
}
.divider-white{
  border-bottom: 10px solid white;
  width: 50px;
  margin-bottom: 50px;
}

button{
  border: 0;
}

.end-page{
  background: rgba($color: #fff, $alpha: 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  height: 50px;
}