.PageTitle{
    h2{
      padding-bottom: 20px;
    }

    .main-title{
        font-size: 3.5rem;
        text-align: center;
        letter-spacing: 7px;
        font-weight: normal;
        margin-top: 100px;
        margin-bottom: 0;
    }
}


@media screen and (max-width: 500px) {
    .PageTitle{
        .main-title{
            font-size: 2.5em;
        }
    }
}