.data-container{
    width: 800px;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    p{
        text-align: justify;
    }
}