@import '../../variables';


.success{
    color: #02b201;
    font-weight: bold;
}

.error{
    color: #f70000;
    font-weight: bold;
}

.bg-contact{
    background-color: $GOLD;
}
.contact-container{
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    h2{
        border-bottom: 1px solid white;
        display: inline-block;
        margin: 0 0 30px 0;
    }
}

.input-container, .info-list-container{
    width: 60%;
}

.form-container{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3{
        margin: 0 0 5px 0;
    }
    
    input{
        border: none;
        width: 94%;
        border-radius: 3px;
        padding: 5px 3%;
        margin-bottom: 20px;
        height: 30px;
    }

    button{
        margin-top: 0 30px;
        padding: 10px 20px;
        background: white;
        border: 0;
        border-radius: 50px;
        font-size: 1rem;
        cursor: pointer;
    }
    .checkbox{
        margin-right: 10px;
        width: 20px;
        height: 20px;
        background-color: #fff;
    }
}

.info-container{
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    
    ul{
        margin: 0;
        padding: 0;
    }
    li{
        margin: 20px 0;
        list-style: none;
        display: flex;
        align-items: center;
        a{
            color: #000;
            &:visited{
                color: #000;
            }
        }
        img{
            height: 30px;
            width: auto;
            margin-right: 5px;
        }
    }
}

.map{
    border: none;
}

.mail-answer{
    display: flex;
    justify-content: center;
}


.success-message{
    background-color: #58c300;
    border-radius: 10px;
    padding: 13px;
    display: inline-block;
    color: white;
    font-weight: bold;
}
.error-message{
    background-color: #f73000;
    border-radius: 10px;
    padding: 13px;
    display: inline-block;
    color: white;
    font-weight: bold;
}


@media screen and (max-width: 1170px){
    .contact-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0;
    }
    .form-container{
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
        section{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        h3{
            align-self: flex-start;
            margin-left: 10%;
        }
        .checkbox{
            margin-top: 0;
            margin-left: 6% !important;
            margin-bottom: 0;
            margin-right: 10px;
            width: 40px;
            height: 40px;
        }
        .checkbox-container{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-top: 20px;
        }
        .checkbox{
            margin-left: 10%;
        }
        button{
            margin: 30px 0;
        }
        input{
            width: 80%;
            margin: 10px auto;
        }
    }
    .info-container{
        width: 90%;
        align-self: center;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        ul{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            li{
                margin: 0;
            }
        }
    }
}