.ReactGridGallery{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 50%;
    margin: 0 auto;
}

@media screen and (max-width: 1400px) {
    .ReactGridGallery {
        max-width: 80%;
    }
}