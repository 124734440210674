@import "../../variables";

.hours-page{

    .day-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-bottom: 25px;
    }
    .day{
        font-size: 2.1rem;
        font-weight: bold;
        width: 200px;
        max-width: 100%;
        text-align: right;
        margin-right: 100px;
    }
    .hours{
        font-size: 1.7rem;
        width: 200px;
        text-align: left;
    }
    .closed{
        color: red;
        font-weight: bold;
    }
    .hours-global{
        max-width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .hours-page{
        .day-container{
            flex-direction: column;
            align-items: center;
            margin-bottom: 60px;
            .hours{
                text-align: center;
            }
            .day{
                text-align: center;
                margin: 0;
            }
        }
    }
}