@import "../../variables";

.nav-large {
    display: flex;
    align-items: baseline;
    background-color: rgba(255,255,255,0.75);
    position: fixed;
    max-height: 80px;
    width: 100vw;
    top: 0;
    z-index: 1;
    ul {
        display: inline-flex;
    }

    li {
        margin-right: 30px;
        list-style: none;
        font-size: 25px;
    }
    h1{
        font-size: 35px;
        margin-left: 10%;
        display: inline-flex;
    }
    a{
        color: #000;
        text-decoration: none;
    }
    #active{
        border-bottom: 3px solid $GOLD;
    }
}

.nav-small{
    a{
        color: $GOLD;
        text-decoration: none;
    }
    h1{
        font-size: 2rem;
    }
    li{
        margin-bottom: 10px;
        list-style: none;
    }
}


/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    height: 90% !important;
    font-size: 25px;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.small-ul{
    #active{
        border-bottom: 1px solid white;
    }
}