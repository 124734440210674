@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville|Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
body {
  font-family: "Roboto", sans-serif;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Baskerville", sans-serif;
  font-weight: normal;
}

button {
  cursor: pointer;
}
button button:focus {
  border: none;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider-black {
  border-bottom: 10px solid black;
  width: 50px;
}

.divider-colored {
  border-bottom: 10px solid #FEBE51;
  width: 50px;
  margin-bottom: 50px;
}

.divider-white {
  border-bottom: 10px solid white;
  width: 50px;
  margin-bottom: 50px;
}

button {
  border: 0;
}

.end-page {
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  height: 50px;
}
.nav-large {
  display: flex;
  align-items: baseline;
  background-color: rgba(255, 255, 255, 0.75);
  position: fixed;
  max-height: 80px;
  width: 100vw;
  top: 0;
  z-index: 1;
}
.nav-large ul {
  display: inline-flex;
}
.nav-large li {
  margin-right: 30px;
  list-style: none;
  font-size: 25px;
}
.nav-large h1 {
  font-size: 35px;
  margin-left: 10%;
  display: inline-flex;
}
.nav-large a {
  color: #000;
  text-decoration: none;
}
.nav-large #active {
  border-bottom: 3px solid #FEBE51;
}

.nav-small a {
  color: #FEBE51;
  text-decoration: none;
}
.nav-small h1 {
  font-size: 2rem;
}
.nav-small li {
  margin-bottom: 10px;
  list-style: none;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  height: 90% !important;
  font-size: 25px;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.small-ul #active {
  border-bottom: 1px solid white;
}
.home {
  display: flex;
  flex-direction: column;
  margin-left: 15%;
  max-width: 900px;
  padding: 30px;
}
.home h2 {
  font-size: 99px;
  margin-bottom: 50px;
  margin-top: 200px;
}
.home h3 {
  font-size: 52px;
  font-family: "Roboto", sans-serif;
  margin: 0;
  letter-spacing: 4.5px;
}
.home p {
  margin: 25px 0 0 0;
  font-size: 25px;
  letter-spacing: 2.4px;
}
.home button {
  font-size: 22px;
  margin-top: 100px;
  width: 300px;
  background-color: #FEBE51;
  border: 3px solid #FEBE51;
  padding: 20px;
  border-radius: 50px;
  font-weight: bold;
}
.home button:hover {
  background-color: white;
  border: 3px solid #FEBE51;
  transition: ease-in-out all 400ms;
}
.home a {
  text-decoration: none;
}
.home .appointment-button {
  color: black;
}

@media screen and (max-width: 1200px) {
  .home {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 500px) {
  .home {
    padding: 15px;
  }
  .home h2 {
    font-size: 4em;
    margin-top: 100px;
  }
  .home h3 {
    font-size: 3em;
    text-align: center;
  }
  .home p {
    font-size: 1.5em;
    text-align: center;
  }
  .home button {
    margin-bottom: 50px;
  }
}
.waterColor {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.8;
}

.homeDog {
  width: auto;
  position: fixed;
  z-index: -2;
  right: -250px;
  bottom: 0;
  overflow: hidden;
  opacity: 0.2;
}

@media screen and (max-width: 700px) {
  .homeDog {
    display: none;
  }
}
.services-container {
  display: flex;
  flex-direction: column;
}
.services-container .container-sub {
  width: 50%;
  display: flex;
  justify-content: flex-start;
}
.services-container .container-sub-left {
  align-items: flex-end;
}
.services-container .container-sub-right {
  align-items: flex-start;
}
.services-container .colored-bg {
  background-color: #FEBE51;
  position: absolute;
  width: 50vw;
  height: 100vh;
  z-index: -1;
}
.services-container .colored-sub-container {
  height: 90%;
}
.services-container .general-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 60%;
}
.services-container h3 {
  font-size: 27px;
  font-weight: bold;
  font-family: "Roboto", "sans-serif";
}
.services-container p {
  font-size: 20px;
  font-family: "Roboto", "sans-serif";
}
.services-container article {
  display: flex;
  justify-content: center;
  align-items: center;
}
.services-container .article-left {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.services-container .article-right {
  text-align: left;
  display: flex;
  justify-content: flex-start;
}
.services-container .services-txt {
  max-width: 80%;
  margin: 0 30px;
}
.services-container img {
  max-height: 80px;
  width: auto;
}
.services-container .icone {
  height: 50px;
  width: auto;
}
.services-container .box-center-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  max-width: 80%;
}
.services-container .box-center-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 80%;
}
.services-container .service-Dog {
  max-height: 80vh;
  width: auto;
  margin-top: 100px;
}

@media screen and (max-width: 1030px) {
  .general-container {
    flex-direction: column;
  }
  .service-Dog {
    display: none;
  }
  .services-container {
    height: 100%;
  }
  .services-container .colored-bg {
    width: 0;
  }
  .services-container .container-sub {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .services-container .container-sub-left {
    align-items: center;
    background: #FEBE51;
  }
  .services-container .article-left {
    flex-direction: row-reverse;
    text-align: left;
    justify-content: flex-end;
    width: 100%;
  }
  .services-container .article-right {
    width: 80vw;
    justify-content: flex-start;
  }
  .services-container .box-center {
    align-items: center;
    margin-bottom: 50px;
  }
  .services-container .PageTitle {
    background: #FEBE51;
  }
  .services-container .icone {
    max-height: 40px;
  }
  .services-container h3 {
    font-size: 20px;
  }
  .services-container p {
    font-size: 16px;
    text-align: justify;
  }
  .services-container .divider-black {
    border-bottom: 10px solid white;
  }
}
.PageTitle h2 {
  padding-bottom: 20px;
}
.PageTitle .main-title {
  font-size: 3.5rem;
  text-align: center;
  letter-spacing: 7px;
  font-weight: normal;
  margin-top: 100px;
  margin-bottom: 0;
}

@media screen and (max-width: 500px) {
  .PageTitle .main-title {
    font-size: 2.5em;
  }
}
.main-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.tarif-sub-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.tarif-container {
  background-color: white;
  border: 2px #FEBE51 solid;
  display: inline-block;
  padding: 20px;
  margin: 30px;
}
.tarif-container header h3 {
  font-family: "Roboto", "sans-serif";
  font-size: 29px;
  font-weight: bold;
  margin: 0;
}
.tarif-container header p {
  font-family: "Baskerville", "sans-serif";
  font-size: 17px;
  margin: 4px 0;
}
.tarif-container ul {
  padding: 0;
}
.tarif-container li {
  display: flex;
  list-style: none;
  border-bottom: 1px #E8E8E8 solid;
  justify-content: flex-end;
  margin-bottom: 7px;
  padding-bottom: 4px;
}
.tarif-container li .table-name {
  margin-right: auto;
  text-align: left;
}
.tarif-container li .price {
  text-align: right;
  margin-left: 40px;
  font-style: italic;
}

.small {
  font-style: italic;
  font-size: 12px;
}

#grooming {
  border-color: black;
}

@media only screen and (min-width: 670px) {
  .tarif-container {
    min-width: 557px;
  }
}
.push-to-top img {
  height: 50px;
  width: 50px;
  cursor: pointer;
}
.watercolor-team-top {
  height: 50vh;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  position: fixed;
  right: -50px;
  top: -50px;
  z-index: -1;
  opacity: 0.4;
  filter: alpha(opacity=40); /* For IE8 and earlier */
}

.watercolor-team-bottom {
  height: 50vh;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.4;
  filter: alpha(opacity=40); /* For IE8 and earlier */
}

.picture-team {
  max-height: 300px;
  border-radius: 50%;
  border: 7px #FEBE51 solid;
  margin-bottom: 30px;
}

.team-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}

.team-txt {
  margin: 0 30px;
  max-width: 600px;
  text-align: justify;
}
.team-txt #team-title {
  font-weight: bold;
  font-size: 28px;
  border-bottom: 1px #FEBE51 solid;
}
.ReactGridGallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 50%;
  margin: 0 auto;
}

@media screen and (max-width: 1400px) {
  .ReactGridGallery {
    max-width: 80%;
  }
}
.success {
  color: #02b201;
  font-weight: bold;
}

.error {
  color: #f70000;
  font-weight: bold;
}

.bg-contact {
  background-color: #FEBE51;
}

.contact-container {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
}
.contact-container h2 {
  border-bottom: 1px solid white;
  display: inline-block;
  margin: 0 0 30px 0;
}

.input-container, .info-list-container {
  width: 60%;
}

.form-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-container h3 {
  margin: 0 0 5px 0;
}
.form-container input {
  border: none;
  width: 94%;
  border-radius: 3px;
  padding: 5px 3%;
  margin-bottom: 20px;
  height: 30px;
}
.form-container button {
  margin-top: 0 30px;
  padding: 10px 20px;
  background: white;
  border: 0;
  border-radius: 50px;
  font-size: 1rem;
  cursor: pointer;
}
.form-container .checkbox {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background-color: #fff;
}

.info-container {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
}
.info-container ul {
  margin: 0;
  padding: 0;
}
.info-container li {
  margin: 20px 0;
  list-style: none;
  display: flex;
  align-items: center;
}
.info-container li a {
  color: #000;
}
.info-container li a:visited {
  color: #000;
}
.info-container li img {
  height: 30px;
  width: auto;
  margin-right: 5px;
}

.map {
  border: none;
}

.mail-answer {
  display: flex;
  justify-content: center;
}

.success-message {
  background-color: #58c300;
  border-radius: 10px;
  padding: 13px;
  display: inline-block;
  color: white;
  font-weight: bold;
}

.error-message {
  background-color: #f73000;
  border-radius: 10px;
  padding: 13px;
  display: inline-block;
  color: white;
  font-weight: bold;
}

@media screen and (max-width: 1170px) {
  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
  }
  .form-container {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-container section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .form-container h3 {
    align-self: flex-start;
    margin-left: 10%;
  }
  .form-container .checkbox {
    margin-top: 0;
    margin-left: 6% !important;
    margin-bottom: 0;
    margin-right: 10px;
    width: 40px;
    height: 40px;
  }
  .form-container .checkbox-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
  }
  .form-container .checkbox {
    margin-left: 10%;
  }
  .form-container button {
    margin: 30px 0;
  }
  .form-container input {
    width: 80%;
    margin: 10px auto;
  }
  .info-container {
    width: 90%;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .info-container ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .info-container ul li {
    margin: 0;
  }
}
.hours-page .day-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
}
.hours-page .day {
  font-size: 2.1rem;
  font-weight: bold;
  width: 200px;
  max-width: 100%;
  text-align: right;
  margin-right: 100px;
}
.hours-page .hours {
  font-size: 1.7rem;
  width: 200px;
  text-align: left;
}
.hours-page .closed {
  color: red;
  font-weight: bold;
}
.hours-page .hours-global {
  max-width: 100%;
}

@media screen and (max-width: 700px) {
  .hours-page .day-container {
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
  }
  .hours-page .day-container .hours {
    text-align: center;
  }
  .hours-page .day-container .day {
    text-align: center;
    margin: 0;
  }
}
.data-container {
  width: 800px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.data-container p {
  text-align: justify;
}
