@import "../../variables";

.main-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}

.tarif-sub-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.tarif-container{
    background-color: white;
    border: 2px $GOLD solid;
    display: inline-block;
    padding: 20px;
    margin: 30px;

    header{
        h3{
            font-family: 'Roboto', 'sans-serif';
            font-size: 29px;
            font-weight: bold;
            margin: 0;
        }
        p{
            font-family: 'Baskerville', 'sans-serif';
            font-size: 17px;
            margin: 4px 0;
        }
    }
    ul{
        padding: 0;
    }
    li{
        display: flex;
        list-style: none;
        border-bottom: 1px #E8E8E8 solid;
        justify-content: flex-end;
        margin-bottom: 7px;
        padding-bottom: 4px;
        .table-name{
            margin-right: auto;
            text-align: left;

        }
        .price{
            text-align: right;
            margin-left: 40px;
            font-style: italic;
        }
    }
}
.small{
    font-style: italic;
    font-size: 12px;
}

#grooming{
    border-color: black;
}

@media only screen and (min-width: 670px) {
    .tarif-container {
        min-width: 557px;
    }
}