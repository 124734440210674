@import "../../variables";

.watercolor-team-top{
    height: 50vh;
    transform: rotate(180deg);
    position: fixed;
    right: -50px;
    top: -50px;
    z-index: -1;
        opacity: 0.4;
    filter: alpha(opacity=40); /* For IE8 and earlier */
}

.watercolor-team-bottom{
    height: 50vh;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0.4;
    filter: alpha(opacity=40); /* For IE8 and earlier */
}

.picture-team{
    max-height: 300px;
    border-radius: 50%;
    border: 7px $GOLD solid;
    margin-bottom: 30px;
}

.team-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
}

.team-txt{
    #team-title{
        font-weight: bold;
        font-size: 28px;
        border-bottom: 1px $GOLD solid;

    }
    margin: 0 30px;
    max-width: 600px;
    text-align: justify;
}